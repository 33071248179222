<template>
  <div id="safe">
    <tmpl_header />

    <div class="container">
      <sidebar act="safe" />

      <div class="main">
        <div class="card">
          <h1>修改密码</h1>
          <div class="form form-label-block">
            <!--ul class="steps">
              <li class="done">验证身份</li>
              <li :class="{ done: step >= 2 }">重置密码</li>
              <li :class="{ done: step >= 3 }">完成</li>
            </ul-->
            <div class="form-group">
              <div>
                <span class="prefix icon icon_mobile"></span>
                <input
                  type="text"
                  v-model="mobile"
                  autocomplete="off"
                  placeholder="请输入手机号码"
                />
              </div>
            </div>
            <div class="form-group">
              <div>
                <span class="prefix icon icon_safe"></span>
                <input
                  type="text"
                  v-model="verify"
                  autocomplete="off"
                  placeholder="请输入验证码"
                />
                <button class="suffix" @click="sendSms">
                  {{ send > 0 ? send + "秒后可重发" : "获取短信验证码" }}
                </button>
              </div>
            </div>
            <div class="form-group">
              <div>
                <span class="prefix icon icon_lock"></span>
                <input
                  type="password"
                  v-model="password"
                  autocomplete="new-password"
                  placeholder="请输入新密码"
                />
              </div>
            </div>
            <div class="form-group">
              <div>
                <span class="prefix icon icon_lockv"></span>
                <input
                  type="password"
                  v-model="vpassword"
                  autocomplete="new-password"
                  placeholder="请再输入一遍密码"
                />
              </div>
            </div>
            <div v-if="step === 3" class="success">
              密码修改完成
              <p>现在可以用新密码登录您的账号</p>
            </div>

            <div v-if="step <= 2" class="form-group" style="padding-top: 30px">
              <button class="btn" @click="next">提交</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>
<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
      step: 1,

      mobile: "",
      vmobile: "",
      verify: "",
      password: "",
      vpassword: "",

      send: 0,
      sendTime: 0,
      save: 0,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/safe/password") },
      });
  },

  methods: {
    sendSms: function () {
      const moblieReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      if (this.send) return;

      if (!moblieReg.test(this.mobile)) {
        return store.dispatch("toast", "手机号码不正确");
      }

      this.vmobile = this.mobile;

      return api.post(apis.sms.send, { phoneNumber: this.mobile }).then(() => {
        this.send = 60;
        this.sendTime = new Date().getTime();
        this.doTimer();
      });
    },

    doTimer: function () {
      const that = this;
      setTimeout(function () {
        const now = new Date().getTime();
        const delt = 60 - Math.floor((now - that.sendTime) / 1000);
        if (delt > 0) {
          if (that.send != delt) that.send = delt;

          that.doTimer();
        } else {
          that.send = 0;
        }
      }, 100);
    },

    next: function () {
      const moblieReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      // if (this.step === 1) {
      if (!moblieReg.test(this.mobile)) {
        return store.dispatch("toast", "手机号码不正确");
      }

      if (!this.verify) {
        return store.dispatch("toast", "请输入验证码");
      }

      if (this.vmobile != this.mobile) {
        return store.dispatch("toast", "修改号码后请重新获取验证码");
      }

      if (this.password != this.vpassword) {
        return store.dispatch("toast", "两次密码输入不一致");
      }

      if (!this.password || this.password.length < 6) {
        return store.dispatch("toast", "密码长度不得小于6位");
      }

      // this.step = 2;
      // } else {
      this.submit();
      // }
    },

    submit: function () {
      this.save = 1;

      return api
        .post(apis.common.forgot, {
          phoneNumber: this.mobile,
          verifyCode: this.verify,
          password: this.password,
        })
        .then(() => {
          this.save = 0;
          this.mobile = "";
          this.verify = "";
          this.password = "";
          this.vpassword = "";
          this.step = 3;
        });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("../../assets/less/form.less");
@import url("./safe.less");
</style>
